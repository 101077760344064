@import "../../node_modules/bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

body {
  background: url("/public/bg.jpg") repeat-x, rgb(239, 176, 13);
}

.logo{
  width: 100%;
  background:rgba(255,255,255,0.4);
  padding: 10px;
  margin: 10px 0;
  border-radius: 40px;
  height: auto;
}

.social {
  margin: 10px auto;
  border-radius: 40px;
  background:rgba(255,255,255,0.4);
}

form.container{
  background:rgba(255,255,255,0.4);
  border-radius: 10px;
  padding: 10px;
}

.val{
  margin:10px;
  background:rgba(255,255,255,0.7);
  padding: 10px;
  border-radius: 10px;
}

.text{
  margin: 10px auto;
  background:rgba(255,255,255,0.7);
  padding: 10px;
  font-size: 1.3em;
  border-radius: 10px;
}

.results {
  margin: 10px auto;
  background:rgba(255,255,255,0.7);
  padding: 10px;
  font-size: 1.1em;
  border-radius: 10px;
}

.results td, .results th{
  text-align: center;
}

.video{
  margin-top: 30px;
}

.absolute{
  position: fixed;
  top: 0px;
}

.social > div > a {
  margin:auto;
  padding: 10px;
}

.shadow {
  background: rgba(0,0,0,0.4);
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.faq {
  z-index: 2;
  top: 0;
  bottom:0;
  left:0;
  right: 0;
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
}

.image-gallery-close {
  top:0;
  right:0;
  position: absolute;
}

.navbar-nav {
  font-size: larger;
  font-weight: bold;
}
